import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import './components/partnership/styles.scss';
import OurPartners from './components/partnership/our-partners';

export default function Partners() {
  return (
    <Layout>
      <Seo title="Partners" />
      <OurPartners />
    </Layout>
  );
}
